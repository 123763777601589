// cssをインポート
import "../../node_modules/destyle.css/destyle.css";
import "../sass/style.scss";

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

require('./common');
require('./form');
