jQuery(function ($) {

	//送信ボタン活性化チェック
	$('.form__input input').on('input blur', function(){
		checkSubmitButton();
	});
	$('.form__input select').on('input blur', function(){
		checkSubmitButton();
	});
	function checkSubmitButton() {
		let disabled = false;
		$('.form__input .required').each(function(){
			if ( !$(this).prop('disabled') ) {
				if ( $(this).attr('type') == 'checkbox' ) {
					if ( !$(this).prop('checked') ) {
						disabled = true;
					}
				} else {
					if ( !$(this).val() ) {
						disabled = true;
					}
				}
			}
		});
		$('.form__submit-button').prop('disabled', disabled);
	}

	//送信ボタンクリック時
	$('.form__submit-button').on('click',function() {
		//二重送信防止のためボタンを非活性化
		$('.form__submit-button').prop('disabled', true);

		//メッセージエリア初期化
		$('.form-message').hide();
		$('.form-message__item').remove();

		let result = true;
		let messages = [];
		//必須項目チェック
		$('.form__input .required').each(function(){
			if ( !$(this).prop('disabled') && !$(this).val() ) {
				result = false;
				let label = $(this).parents('.form__item').find('.form__label').text();
				let message = label.replace(/必須/g,'') + 'を入力してください。';
				if ( $.inArray(message,messages) < 0 )  {
					messages.push(message);
				}
			}
		});

		//必須項目チェッククリア後チェック
		if ( result ) {
			//email形式チェック
			let regex = /^[+\w.-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
			$('.form__input input[name="email"]').each(function(){
				let email = $(this).val();
				if (! regex.test(email)) {
					result = false;
					let message = '有効なメールアドレスを入力してください。';
					if ( $.inArray(message,messages) < 0 )  {
						messages.push(message);
					}
				}
			});

			//パスワードチェック
			if ( $('#password_confirm').length ) {
				let password = $('#password').val();
				let password_c = $('#password_confirm').val();
				if ( password.length < 8 ) {
					result = false;
					let message = 'パスワードは8文字以上入力してください。';
					if ( $.inArray(message,messages) < 0 )  {
						messages.push(message);
					}
				}
				if ( result ) {
					let regex_a = /[a-z]/;
					let regex_A = /[A-Z]/;
					let regex_9 = /[0-9]/;
					let regex_a9 = /^[0-9a-zA-Z]*$/;
					if (!regex_a.test(password) || !regex_A.test(password) || !regex_9.test(password) || !regex_a9.test(password) ) {
						result = false;
						let message = 'パスワードは数字、小文字、大文字を含めた半角英数字で入力してください。';
						if ( $.inArray(message,messages) < 0 )  {
							messages.push(message);
						}
					}
				}
				if ( result ) {
					if ( password != password_c ) {
						result = false;
						let message = 'パスワードとパスワード（確認用）が一致しません。';
						if ( $.inArray(message,messages) < 0 )  {
							messages.push(message);
						}
					}
				}
			}

			//日付チェック
			if ( $('.post-date').length ) {
				$('.post-date').each(function(){
					if ( $(this).val().length ) {
						if ( !isDate( $(this).val() ) ) {
							result = false;
							let label = $(this).parents('.form__item').find('.form__label').text();
							label = label.replace(/必須/g,'');
							label = label.replace(/任意/g,'');
							let message = label + 'の日付が不正です。';
							if ( $.inArray(message,messages) < 0 )  {
								messages.push(message);
							}
						}
					}
				});
			}
		}

		if ( result ) {
			if ( $(this).parents('#payment-form').length ) {
				//決済フォームの場合
				let shop_info = JSON.parse($('#shop-info-json-data').text());
				if ( $("input[name='payment_method_select']:checked").val() == 'card' ) {
					Multipayment.init(shop_info.shop_id);	 // ショップID
					Multipayment.getToken(
						{
							cardno: $('#cardnum').val(),   // カード番号
							expire: $('#expire_year').val().slice(-2) + ("0" + $('#expire_month').val()).slice(-2),             // カード有効期限
							securitycode: $('#securityCode').val(),          // セキュリティコード
							holdername: $('#cardName').val(),    // カード名義人
						}, callbackFunction         // MPクレカトークン取得後に実行するコールバック関数
					);
				} else {
					$('#payment_method').val($("input[name='payment_method_select']:checked").val());
					$('#payment-form').get(0).submit();
				}
			} else if ( $(this).hasClass('show-confirm') ) {
				//ボタン再活性化
				$('.form__submit-button').prop('disabled', false);
				//モーダルに入力内容を設定
				$('.register-profile__confirm-modal-column-value.member_id').text($('#member_id').val());
				$('.register-profile__confirm-modal-column-value.nickname').text($('#nickname').val());
				let value = '指定なし';
				if ( $('#birthday').val() ) {
					value = $('#birthday').val().replace(/-/g,'/');
				}
				$('.register-profile__confirm-modal-column-value.birthday').text(value);
				value = '指定なし';
				if ( $('#gender').val() ) {
					value = $('#gender option[value="'+ $('#gender').val() +'"]').text();
				}
				$('.register-profile__confirm-modal-column-value.gender').text(value);
				value = '指定なし';
				if ( $('#area').val() ) {
					value = $('#area option[value="'+ $('#area').val() +'"]').text();
				}
				$('.register-profile__confirm-modal-column-value.area').text(value);
				//確認モーダル表示
				$('#confirm-modal').fadeIn();
			} else {
				//上記以外の場合はそのまま送信
				$(this).parents('.form').get(0).submit();
			}
		} else {
			let $button = $(this);
			//エラーメッセージ表示
			let $message_area = $('.form-message');
			messages.forEach(function(message){
				$message_area.append('<p class="form-message__item">' + message + '</p>');
			})
			$(window).scrollTop(0);
			$message_area.fadeIn(function(){
				//ボタン再活性化
				$('.form__submit-button').prop('disabled', false);
			});
		}
	});

    // MPクレカトークン取得後処理
    function callbackFunction ( result ){
        // 処理結果コードが'000'以外の時はエラー
        if( result.resultCode != '000' ){
            let message = 'カード情報にエラーがあります。';
            let code = Number(result.resultCode);
            if ( code >= 100 && code <= 102  ) {
                message = 'カード番号が不正です。';
            } else if ( code >= 110 && code <= 113) {
                message = '有効期限が不正です。';
            } else if ( code >= 121 && code <= 122) {
                message = 'セキュリティコードが不正です。';
            } else if ( code >= 131 && code <= 132) {
                message = '名義人が不正です。';
            }
			console.log('error_code:'+code);
            let $message_area = $('.form-message');
			$message_area.append('<p class="form-message__item">' + message + '</p>');
			$message_area.fadeIn(function() {
				//ボタン再活性化
				$('.form__submit-button').prop('disabled', false);
			});
        } else {
            $("#cardToken").val(result.tokenObject.token);
            // フォームをsubmitして購入処理を進める
			$('#payment-form').get(0).submit();
        }
    }

	//数値項目で数字以外を削除
	$('.form__item input.num-only').on('input',function() {
		let val = $(this).val();
		$(this).val( val.replace(/\D+/g, '') );
	});

	//日付項目（年）
	$('.form__item input.year').on('input',function() {
		const max = $(this).attr('maxlength');  //最大文字数
    	const current = $(this).val().length;  //現在の文字数
		if(current >= max) {
			$('input.month').trigger('focus');
		}
		$date_form = $(this).parents('.form__input').find('.post-date');
		if ( $date_form.length ) {
			setPostDate($date_form);
		}
	});
	//日付項目（月）
	$('.form__item input.month').on('input',function() {
		const max = $(this).attr('maxlength');  //最大文字数
    	const current = $(this).val().length;  //現在の文字数
		if(current >= max) {
			$('input.day').trigger('focus');
		}
		$date_form = $(this).parents('.form__input').find('.post-date');
		if ( $date_form.length ) {
			setPostDate($date_form);
		}
	});
	$('.form__item input.day').on('input',function() {
		$date_form = $(this).parents('.form__input').find('.post-date');
		if ( $date_form.length ) {
			setPostDate($date_form);
		}
	});
	function setPostDate(target) {
		const year = target.parents('.form__input').find('input.year').val();
		const month = target.parents('.form__input').find('input.month').val();
		const day = target.parents('.form__input').find('input.day').val();
		target.val(year + '-' + ('0' + month).slice(-2) + '-' + ('0'+day).slice(-2) );
	}
	function isDate(str) {
		const date_array = str.split('-');
		const y = Number(date_array[0]);
		const m = Number(date_array[1]);
		const d = Number(date_array[2]);
		var date = new Date(y,m-1,d);
		if(date.getFullYear()===y && date.getMonth()===m-1 && date.getDate()===d) {
			return true;
		} else {
			return false;
		}
	}

	//生年月日クリアボタン
	$('.form__birthday-clear').on('click',function() {
		$('#birthday_year').val('');
		$('#birthday_month').val('');
		$('#birthday_day').val('');
		$('#birthday').val('');
	});

    if ( $('#payment-form').length ) {
        // 決済方法ラジオボタン変更時処理
        $( 'input[name="payment_method_select"]:radio' ).on('change', function() {
            //決済方法変更時
            var radioval = $(this).val();
            if ( radioval == 'card' ) {
                //$('#card-input-form').slideDown();
                $('#card-input-form input').prop('disabled', false);
                $('#card-input-form select').prop('disabled', false);
            } else {
                //$('#card-input-form').slideUp();
                $('#card-input-form input').prop('disabled', true);
                $('#card-input-form select').prop('disabled', true);
            }
			checkSubmitButton();
        });
    }

	if ( $('.register-profile__confirm-modal-close').length ) {
		$('.register-profile__confirm-modal-close').on('click',function() {
			$('#confirm-modal').fadeOut();
		});
	}
});
