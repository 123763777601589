jQuery(function ($) {
	/**
	 * メニュー開閉関連
	 */
	if ( $('.header__menu-button').length ) {
		$('.header__menu-button').on('click',function(e){
			e.stopPropagation();
			$(this).toggleClass('active');
			$('.menu').toggleClass('open');
		});
		$('.menu').on('click',function(e){
			e.stopPropagation();
		});
		$('body').on('click',function(e){
			if ( $('.header__menu-button').hasClass('active') ) {
				//メニュー開いてる時にメニュー以外の部分をクリックされたらメニュー閉じる
				$('.header__menu-button').removeClass('active');
				$('.menu').removeClass('open');
			}
		});
	}

	/**
	 * サブスク購読キャンセルモーダル開閉
	 */
	if ( $('#cancel-modal-open').length ) {
		$('#cancel-modal-open').on('click',function() {
			$('.sub-detail__cancel-modal').fadeIn();
		});
		$('.sub-detail__cancel-modal #cancel-cancel').on('click',function() {
			$('.sub-detail__cancel-modal').fadeOut();
		});
	}

	/**
	 * カスタムプロパティ設定
	 */
	setCustomProperty();
	$(window).on('resize',function(){
		setCustomProperty();
	})
	function setCustomProperty() {
		//画面高さ
		const vh = window.innerHeight / 100;
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		//フッター高さ
		const footer_height = $('.siteFooter').innerHeight();
		document.documentElement.style.setProperty('--footer-height', `${footer_height}px`);
	}
});
